import "C:\\Users\\Hsk-XADN\\Desktop\\hyzk\\node_modules\\@vue\\babel-preset-app\\node_modules\\core-js\\modules\\es6.array.iterator.js";
import "C:\\Users\\Hsk-XADN\\Desktop\\hyzk\\node_modules\\@vue\\babel-preset-app\\node_modules\\core-js\\modules\\es6.promise.js";
import "C:\\Users\\Hsk-XADN\\Desktop\\hyzk\\node_modules\\@vue\\babel-preset-app\\node_modules\\core-js\\modules\\es6.object.assign.js";
import "C:\\Users\\Hsk-XADN\\Desktop\\hyzk\\node_modules\\@vue\\babel-preset-app\\node_modules\\core-js\\modules\\es7.promise.finally.js";
import Vue from 'vue';
import App from "./App.vue";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "./assets/style/index.scss";
import "./assets/style/element-variables.scss";
import 'normalize.css/normalize.css';
import animate from 'animate.css';
import router from "./router";
import store from "./store";
import VideoPlayer from 'vue-video-player';
require('video.js/dist/video-js.css');
require('vue-video-player/src/custom-theme.css');
Vue.use(VideoPlayer);
Vue.use(animate);
Vue.use(ElementUI);
Vue.config.productionTip = false;
new Vue({
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
}).$mount('#app');