import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);
var routes = [{
  path: '/',
  name: 'IndexPage',
  component: function component(resolve) {
    return require(['@/views/indexPage.vue'], resolve);
  },
  meta: {
    title: '首  页',
    icon: 'index',
    affix: true,
    noCache: true
  }
}, {
  path: '/privacy',
  name: 'privacy',
  component: function component(resolve) {
    return require(['@/views/agreement/PrivacyPolicy.vue'], resolve);
  },
  meta: {
    title: '隐私政策',
    icon: 'index',
    affix: true,
    noCache: true
  }
}, {
  path: '/useragr',
  name: 'useragr',
  component: function component(resolve) {
    return require(['@/views/agreement/UserAgreement.vue'], resolve);
  },
  meta: {
    title: '用户协议',
    icon: 'index',
    affix: true,
    noCache: true
  }
}];
var router = new VueRouter({
  mode: 'history',
  // base: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : '/',
  routes: routes
});
export default router;